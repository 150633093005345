div.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
img.logo {
  width: 104px;
  height: 104px;
  transform: rotate(45deg);
}
div.header > div.logo > a {
  display: flex;
  align-items: center;
}
div.header span.menu {
  padding: 0 40px;
}
span.logo-text {
  font-size: 48px;
  font-weight: 700;
}

@media (max-width: 1600px) {
  span.logo-text {
    font-size: 40px;
  }
  div.header span.menu {
    padding: 0 32px;
  }
}