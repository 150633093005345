div.policyPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 128px 160px 128px;
}
div.policyPage > h1 {
  font-size: 48px;
  font-weight: 700;
  padding-bottom: 48px;
}
div.policyPage > div.description {
  width: 90%;
  font-size: 28px;
  text-align: left;
  border: 16px solid var(--middlepink);
  border-radius: 64px;
  padding: 32px 64px;
  margin-bottom: 32px;
}
div.policyPage > p.contactForLegal {
  width: 78%;
  font-size: 28px;
}
div.policyPage > h2 {
  font-size: 26px;
  padding: 32px;
}
div.policyPage > p.inform {
  font-size: 24px;
  color: black;
  text-align: left;
}
div.policyPage a {
  text-decoration: underline;
  font-size: 28px;
  font-weight: 600;
}