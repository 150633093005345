div.consultPanel {
  font-size: 24px;
  margin-top: 32px;
}
div.consultPanel > div.content {
  margin-top: 32px;
}
div.categoryBlock {
  display: flex;
  flex-wrap: wrap;
  font-size: 24px;
}