div.formulaPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 128px 160px 128px;
}
div.formulaPage > h1 {
  font-size: 48px;
  font-weight: 700;
  padding-bottom: 32px;
}
div.formulaPage > p {
  font-size: 28px;
  width: 80%;
  padding-bottom: 64px;
}
div.formulaPage > div.button {
  font-size: 32px;
  font-weight: 400;
  background-color: var(--middlepink);
}