div.input-primary {
  display: flex;
  border-radius: 24px;
  padding: 4px 12px;
  margin-bottom: 12px;
  background-color: var(--lightgray);
  width: 100%;
  font-size: 24px;
  text-align: left;
}
div.input-primary > input[type='text'],
div.input-primary > input[type='email'],
div.input-primary > input[type='password'],
div.input-primary > input[type='number'],
div.input-primary > input[type='date'],
div.input-primary > input.pac-target-input,
div.input-primary > textarea {
  flex: 1;
  margin-right: 24px;
  background-color: var(--lightgray);
  border: none;
  width: inherit;
  resize: none;
}
div.input-primary > input[type='checkbox'] {
  width: 32px;
  height: 32px;
}
p.description {
  font-size: 16px !important;
  height: 20px;
  text-align: left;
  padding-left: 20px;
}
p.inform {
  color: green;
}
p.warn {
  color: chocolate;
}
p.error {
  color: red;
}

div.input-primary > textarea::-webkit-scrollbar,
div.input-normal > textarea::-webkit-scrollbar {
  width: 16px;
}
div.input-primary > textarea::-webkit-scrollbar-track,
div.input-normal > textarea::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: var(--lightpink)
}
div.input-primary > textarea::-webkit-scrollbar-thumb,
div.input-normal > textarea::-webkit-scrollbar-thumb {
  background-color: #fd2d93;
  border-radius: 6px;
  width: 12px;
}

div.input-normal {
  display: flex;
  border-radius: 6px;
  border: 1px solid black;
  padding: 4px 12px;
  margin-top: 12px;
  width: 100%;
  font-size: 24px;
  text-align: left;
}
div.input-normal > textarea {
  flex: 1;
  margin-right: 24px;
  border: none;
  width: inherit;
  resize: none;
  background-color: var(--lightpink);
}

div.input-detailed {
  font-size: 24px;
  text-align: left;
}
div.input-detailed > input {
  border: 1px solid var(--lightgray);
  border-radius: 6px;
}
div.input-detailed > input + span {
  font-size: 18px;
}

div.input-search {
  display: flex;
  border-radius: 6px;
  border: 1px solid gray;
  padding: 12px;
  width: 50%;
  font-size: 18px;
}
div.input-search > img {
  width: 30px;
  margin-right: 8px;
  filter: invert(62%) sepia(30%) saturate(3727%) hue-rotate(128deg);
}
div.input-search > input {
  margin-right: 0px !important;
}

div.input-normal > input[type='text'],
div.input-normal > input[type='number'],
div.input-normal > input[type='email'],
div.input-search > input {
  flex: 1;
  margin-right: 24px;
  border: none;
  width: inherit;
  background-color: var(--lightpink);
}

div.input-primary > span,
div.input-normal > span {
  flex-shrink: 0;
  padding-right: 24px;
}
div.input-primary > img,
div.input-normal > img  {
  width: 30px;
  margin-right: 8px;
}

input:focus-visible,
textarea:focus-visible {
  outline: none;
}

div.w-content {
  width: fit-content;
}

div.primary-dropdown {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-bottom: 12px;
  font-size: 24px;
  text-align: left;
}
div.primary-dropdown > div.dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 24px;
  padding: 4px 12px;
  background-color: var(--lightgray);
  width: 100%;
  font-size: 24px;
  text-align: left;
}
div.primary-dropdown > div.dropdown-header > span:nth-child(2) {
  text-overflow: ellipsis;
  overflow: hidden;
}
div.primary-dropdown > div.dropdown-header > img {
  width: 24px;
}
div.primary-dropdown > div.dropdown-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  margin-left: 20px;
  background-color: #94989d9c;
  top: 100%;
  width: calc(100% - 20px - 20px);
  max-height: 300px;
  overflow-y: auto;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  z-index: 9999;
  cursor: pointer;
}
div.primary-dropdown > div.dropdown-content > p {
  margin: 6px 0px;
  text-align: center;
}
div.primary-dropdown>div.dropdown-content>p:hover {
  font-weight: 700;
}

div.disable {
  color: #a0a0a0;
}