div.footer {
  display: flex;
  background-color: #192765;
  padding: 24px 0 72px 96px;
  margin: 0 calc((-1) * (100vw - 100%)/2 + 10px);
}
div.footer > div.legal, 
div.footer > div.links, 
div.footer > div.networks, 
div.footer > div.contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 32px;
}
div.footer > div > a, 
div.footer > div > span {
  color: white;
  font-size: 16px;
}