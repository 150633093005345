div.icon-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}
div.icon-button > img {
  width: 90px;
  padding: 0px 10px 10px 10px;
  cursor: pointer;
}
div.icon-button > img:hover,
div.icon-button > img.selected {
  filter: invert(30%) sepia(72%) saturate(2177%) hue-rotate(309deg);
}
div.icon-button > img.mini {
  width: 60px;
  padding: 0px 10px 10px 10px;
}
div.icon-button > span {
  font-size: 18px;
}
div.icon-button>span.mini {
  font-size: 12px;
}

div.categoryButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid black;
  border-radius: 6px;
  font-size: 24px;
  width: 20%;
  height: 16vh;
  min-width: 234px;
  min-height: 136px;
  padding: 12px;
  margin: 24px 24px 24px 0px;
  cursor: pointer;
}