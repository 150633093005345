div.accordionGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
  width: 100%;
}
div.accordionGroup > div.item {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}
div.accordionGroup > div.item > div.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 24px;
  height: 48px;
  padding: 12px 24px;
  background-color: var(--middlepink);
  cursor: pointer;
}
div.accordionGroup > div.item > div.title > span {
  font-size: 24px;
}
div.accordionGroup > div.item > div.title > img {
  width: 48px;
  height: 48px;
}

div.accordionGroup > div.item > div.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 24px;
}
div.accordionGroup > div.item > div.content > p {
  font-size: 20px;
  text-align: left;
}
div.accordionGroup > div.item > div.content > p.note {
  border: 1px solid black;
  padding: 10px 12px;
}
div.accordionGroup > div.item > div.content > p a {
  color: blue;
  text-decoration: none;
  font-size: 22px;
}
div.accordionGroup > div.item > div.content > p > span.article {
  color: blue;
  font-size: 24px;
  font-weight: 600;
}
div.accordionGroup > div.item > div.content > table, tr, td {
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
  padding: 12px;
}

@keyframes accordionAnim {
  0% {opacity: 0; max-height: 5%;}
  100% {opacity: 1; max-height: 100%;}
}
@keyframes rotateAnim {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(180deg);}
}