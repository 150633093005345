body {
  margin: 0;
  background-color: #F8F1F4;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --pink: #FE2E93;
  --blue: #04d5b3;
  --middlepink: #fa9dca;
  --lightgray: #c0c0c0;
  --lightpink: #F8F1F4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
