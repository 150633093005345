div.payment {
  padding: 96px 0;
}
div.payment > p {
  font-size: 48px;
  text-align: left;
  margin-bottom: 64px;
}
div.payment > div.panel {
  width: 80%;
  margin: auto;
}
div.payment > div.panel > div:first-child > p {
  letter-spacing: 0.15rem;
  font-size: 18px;
  font-weight: 500;
}

img.credit-card-icon {
  width: 48px;
  margin-right: 12px;
}
img.credit-card-icon + span {
  font-size: 32px;
}

div.panel > p {
  text-align: left;
  margin-top: 24px;
}
div.panel > div.form-group > div {
  margin-top: 24px;
}
div.panel > div.form-group > div.row > div {
  display: flex;
  flex-direction: column;
  font-size: 24px;
  font-weight: 500;
}
div.panel > div.form-group > div.row span {
  margin-bottom: 12px;
  text-align: left;
}
div.panel>div.form-group>div.row span.description {
  margin-top: 6px;
  font-size: 18px;
}

div.panel > div.form-group > div.button {
  font-size: 28px;
  margin: auto;
}
div.panel > div.form-group > div.disable {
  background-color: #A0A0A0;
  color: black
}
div.panel > div.form-group > p {
  text-align: left;
  margin-top: 24px;
  margin-left: 42px;
  text-decoration: underline;
  font-size: 20px;
}
div.agreement > input[type='checkbox'] {
  width: 32px;
  border: 1px solid black;
  margin-right: 12px;
}
div.agreement > span {
  font-size: 20px;
}

.StripeElement {
  padding: 8px 12px;
  border: 2px solid var(--lightgray);
  border-radius: 12px;
}