div.notebookItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  border: 2px solid black;
  margin-top: 24px;
  padding: 6px 24px;
  font-size: 24px;
}
div.notebookItem > div:first-child {
  cursor: pointer;
}
div.notebookItem > div:first-child > img {
  width: 48px;
  margin-right: 24px;
}
div.notebookItem > div:last-child > img {
  width: 32px;
  margin-right: 12px;
  cursor: pointer;
}