div.share {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  font-size: 24px;
}
div.share > div.tabHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
div.share > div.tabHeader > span {
  padding: 14px 24px;
  width: 15%;
}
div.share > div.tabHeader > span.selected {
  border-bottom: 4px solid var(--pink);
}
div.share > div.tabContent {
  margin-top: 24px;
}