div.gMapPanel {
  font-size: 24px;
  margin-top: 32px;
}
div.gMapPanel > div:first-child {
  margin-bottom: 32px;
}
div.gMapPanel > div:first-child > img {
  width: 60px;
  margin-right: 24px;
  cursor: pointer;
}
div.gMapPanel > div.gMapContent {
  width: 70%;
  border: 4px solid #fd2d93;
  border-radius: 8px;
  margin: auto;
}
div.gMapPanel > div.gMapContent > div:not(:first-child) {
  border-radius: 4px;
}