div.pathBar {
  display: flex;
  flex-direction: row;
  align-items: center;
}
div.pathBar > div.block {
  display: flex;
  flex-direction: row;
}
div.pathBar > div.block > img {
  width: 48px;
  margin-right: 24px;
}
div.pathBar > div.block > span {
  font-size: 24px;
  margin-right: 24px;
  padding: 2px 6px;
  cursor: pointer;
}
div.pathBar > div.block > span:hover {
  border: 1px solid black;
  border-radius: 4px;
}