.App {
  text-align: center;
  padding: 24px 208px;
}
.carousel-indicators {
  margin-bottom: -5rem;
}
.carousel-inner {
  padding-top: 32px;
}
@media (min-width: 1600px) {
  .App {
    width: 100%;
  }
}
a,
a:hover {
  font-size: 24px;
  color: black;
  text-decoration: none;
}
a.button {
  background-color: var(--pink);
  border: 2px solid var(--pink);
  border-radius: 24px;
  padding: 4px 20px;
  cursor: pointer;
  width: fit-content;
}
a.nobk {
  background-color: transparent;
}
span.bold {
  font-weight: 700;
}

@media (max-width: 1600px) {
  .App {
    padding: 24px 176px;
  }
}
@media (max-width: 1600px) {
  .App {
    padding: 24px 160px;
  }
}
@media (max-width: 1440px) {
  .App {
    padding: 24px 144px;
  }
}