div.documentAddPanel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 103px;
}
div.documentAddPanel > div.formGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
  align-self: flex-end;
}
div.documentAddPanel > div.formGroup > div.add {
  margin-left: 0px;
  font-size: 24px;
}

div.sharePanel {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
div.sharePanel > div.documentBlock {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
div.sharePanel > div.documentBlock > div.select {
  display: flex;
  flex-direction: column;
  width: 50%;
}
div.sharePanel > div.documentBlock > p {
  width: 10%;
}
div.sharePanel > div.documentBlock > div.download {
  width: 40%;
}
div.sharePanel > div.documentBlock > div.download > div.categoryButton {
  width: 100%;
  background-color: var(--lightgray);
  border-radius: 24px;
  border: 0px;
}
div.sharePanel > div.duration {
  display: flex;
  flex-direction: row;
  background-color: var(--lightgray);
  border-radius:24px;
  margin-bottom: 24px;
}
div.sharePanel > div.duration > div.option {
  font-size: 24px;
}
div.sharePanel>div.duration div {
  margin: 0;
}
div.sharePanel > div.buttonGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
}
div.sharePanel > div.buttonGroup > div.button {
  margin: 0px;
  border: 1px solid var(--pink);
}
div.sharePanel > div.buttonGroup > div.button:last-child {
  background-color: white;
}
div.sharePanel>div.buttonGroup>div.button:first-child {
  background-color: var(--pink);
}
div.sharePanel > div.buttonGroup > p {
  margin: 12px 0px;
  font-weight: 500;
}