div.legalPage {
  display: flex;
  flex-direction: column;
  margin-bottom: 96px;
}
div.legalPage > h1 {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 54px;
}
div.legalPage > div.partition {
  margin-bottom: 36px;
}
div.legalPage > div.legalRow {
  display: flex;
  flex-direction: row;
  font-size: 24px;
  margin-left: 72px;
}
div.legalPage > div.legalRow > p {
  margin-bottom: 0px;
  text-align: left;
}
div.legalPage > div.legalRow > p > a {
  text-decoration: underline;
}
div.legalPage > div.legalRow > p.title {
  width: 22%;
  min-width: 300px;
  font-weight: 700;
}