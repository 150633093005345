div.notebookPanel {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}
div.notebookPanel > button.create {
  width: fit-content;
  border-radius: 6px;
  border-color: gray;
  padding: 2px 12px;
}
div.notebookPanel > button.create > img {
  filter: invert(30%) sepia(72%) saturate(2177%) hue-rotate(309deg);
}
div.notebookPanel > div.notebookList {
  height: 450px;
  margin-top: 24px;
  overflow-y: auto;
  font-size: 32px;
}

div.notebookCreatePanel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 24px;
}
div.notebookCreatePanel > p {
  font-size: 42px;
}
div.notebookCreatePanel > div.formGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
  align-self: flex-end;
}
div.notebookCreatePanel > div.formGroup > a.payment {
  padding: 6px 32px;
  font-size: 24px;
}