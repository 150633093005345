div.homePage {
  display: flex;
  flex-direction: column;
  padding-bottom: 192px;
}
div.homePage a.button span {
  padding: 0 20px;
}
div.homePage div.features {
  display: flex;
  padding-top: 104px;
  justify-content: space-between;
  gap: 192px;
}

div.homePage div.features > div.feature {
  flex: 1;
  display: flex;
  flex-direction: column;
}

div.homePage div.features > div.feature > div {
  display: flex;
  align-items: center;
  padding-bottom: 24px;
}

div.homePage div.features > div.feature > div > img {
  border-radius: 12px;
  background-color: #FDC6E1;
  width: 72px;
  height: 72px;
  padding: 8px;
}

div.homePage div.features > div.feature > div > span {
  padding-left: 20px;
  font-size: 26px;
  font-weight: 700;
}

div.homePage div.features > div.feature > p {
  text-align: justify;
  font-size: 20px;
}

div.welcomePart {
  display: flex;
  justify-content: space-between;
  align-items: flex-end ;
  padding-top: 96px;
}
div.welcomePart > div.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 70%;
}
div.welcomePart p {
  text-align: left;
}
div.welcomePart p.large {
  font-size: 60px;
  line-height: 1.2;
}
div.welcomePart p.small {
  font-size: 20px;
  width: 80%;
  text-align: justify;
}
div.welcomePart span.space {
  height: 64px;
}
div.welcomePart > div.house > img {
  width: 65%;
  min-width: 350px;
}

div.missionPart {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 128px calc((-1) * (100vw - 100%)/2 + 10px);
  margin-bottom: 0;
  padding: 80px 0;
  background-color: var(--pink);
  color: black;
}
div.missionPart > p.title {
  font-size: 38px;
  font-weight: 700;
  padding-bottom: 32px;
}
div.missionPart > p.content {
  font-size: 52px;
  font-weight: 800;
}

div.aboutPart {
  display: flex;
  flex-direction: column;
  margin-top: 196px;
}
div.aboutPart > p.title {
  font-size: 44px;
  line-height: 1.05;
}
div.aboutPart > p.content {
  font-size: 24px;
  padding-top: 24px;
}

div.titleBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
div.titleBlock > div {
  height: 2px;
  width: 100%;
  background-color: black;
  flex: 1;
}
div.titleBlock > p {
  font-size: 48px;
  font-weight: 700;
  padding: 0 24px;
  padding-bottom: 48px;
  flex: 1;
}

div.servicesPart {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 196px;
}
div.servicesPart > div.contentBlock {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 48px;
  gap: 48px;
}
div.servicesPart > div.contentBlock > div.roadmap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
}
div.servicesPart > div.contentBlock > div.roadmap > div.road {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 70%;
  transform: translate(-50%, -50%);
  background-color: var(--pink);
}
div.servicesPart > div.contentBlock > div.roadmap > div.point {
  border-style: none;
  border-radius: 16px;
  width: 32px;
  height: 32px;
  background-color: var(--pink);
}
div.servicesPart > div.contentBlock > div.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 48px;
  width: 60%;
}
div.servicesPart > div.contentBlock > div.content > div.service {
  border: 2px solid var(--pink);
  border-radius: 24px;
  background-color: white;
  padding: 12px 24px;
}
div.servicesPart > div.contentBlock > div.content > div.service > p.subtitle {
  font-size: 32px;
  font-weight: 700;
  text-align: left;
  margin: 0;
}
div.servicesPart > div.contentBlock > div.content > div.service > p.subtitle > span.number {
  font-size: 58px;
  color: var(--pink);
  padding-right: 12px;
}
div.servicesPart > div.contentBlock > div.content > div.service > p.subcontent {
  font-size: 20px;
  text-align: left;
  padding-left: 32px;
  margin: 0;
}
div.servicesPart > div.serviceButtonGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
div.servicesPart > div.services {
  display: flex;
  justify-content: space-between;
  gap: 96px;
  padding-top: 96px;
  padding-bottom: 64px;
}
div.servicesPart > div.services > div.service {
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 5px solid #C0C0C0;
  border-radius: 96px;
  width: 100%;
  padding: 24px;
  background-color: white;
}
div.servicesPart > div.services > div.service > p.number {
  background-color: black;
  color: white;
  text-align: center;
  font-size: 48px;
  font-weight: 700;
  width: 72px;
  height: 72px;
  border-radius: 50%;
}
div.servicesPart > div.services > div.service > p.title {
  font-size: 28px;
  font-weight: 700;
  line-height: 1.3;
  flex: 1;
  margin: 0;
  margin-bottom: 12px;
}
div.servicesPart > div.services > div.service > p.content {
  font-size: 24px;
  line-height: 1.05;
  flex: 1;
  margin: 0;
}

div.techsPart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 196px;
}
div.techsPart > div.word {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 72px;
}
div.techsPart > div.word > p {
  width: 54%;
}
div.techsPart > div.word > p:first-child {
  font-size: 42px;
  font-style: italic;
  text-align: left;
}
div.techsPart > div.word > p:last-child {
  font-size: 28px;
  text-align: right;
}
div.techsPart > div.description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  padding-top: 48px;
}
div.techsPart > div.description > p {
  text-align: left;
}
div.techsPart > div.description > span {
  font-size: 26px;
  padding: 24px 0;
}
div.techsPart > div.description > img {
  width: 50%;
  height: 50%;
}

@media (max-width: 1760px) {
  div.homePage div.features {
    gap: 160px
  }
  div.welcomePart p.large {
    font-size: 52px;
  }
  div.servicesPart > div.services > div.service > p.title {
    font-size: 26px;
  }
  div.servicesPart > div.services > div.service > p.content {
    font-size: 22px;
  }
}
@media (max-width: 1600px) {
  div.homePage div.features {
    gap: 136px
  }
  div.homePage div.features > div.feature > div > img {
    width: 64px;
    height: 64px;
  }

  div.homePage div.features > div.feature>div > span {
    padding-left: 16px;
    font-size: 24px;
  }
  div.welcomePart > div.about {
    width: 75%;
  }
  div.welcomePart p.large {
    font-size: 48px;
  }
  div.welcomePart > div.house > img {
    width: 70%;
    min-width: 320px;
  }
  div.titleBlock > p {
    font-size: 44px;
  }
}
@media (max-width: 1440px) {
  div.homePage div.features {
    gap: 100px
  }
  div.homePage div.features > div.feature > div > img {
    width: 56px;
    height: 56px;
  }
  div.homePage div.features > div.feature > div > span {
    padding-left: 12px;
    font-size: 24px;
  }
  div.welcomePart p.large {
    font-size: 44px;
  }
  div.servicesPart > div.services {
    gap: 72px;
  }
  div.servicesPart > div.services > div.service > p.title {
    font-size: 24px;
  }
  div.techsPart > div.word > p:first-child {
    font-size: 36px;
  }
  div.techsPart > div.word > p:last-child {
    font-size: 28px;
  }
  div.titleBlock > p {
    font-size: 42px;
  }
}
@media (max-width: 1400px) {
  div.homePage div.features {
    gap: 80px
  }
}