.emailVerifyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
}

.emailVerifyContainer > p > span {
  font-size: 24px;
  text-decoration: underline;
  cursor: pointer;
}