div.conditionPage {
  padding: 32px 64px;
}
div.conditionPage > h1 {
  font-size: 52px;
  font-weight: 700;
  padding-bottom: 42px;
}
div.conditionPage > p {
  font-size: 28px;
  text-align: left;
}
div.conditionPage > p > a {
  text-decoration: underline;
  font-size: 28px;
}