div.aboutPage {
  display: flex;
  flex-direction: column;
  padding-bottom: 96px;
}
div.aboutPage a.button span {
  padding: 0 20px;
}

div.aboutPage > div.advPart {
  display: flex;
  flex-direction: column;
  padding-top: 96px;
}
div.aboutPage > div.advPart > p.title {
  font-size: 64px;
  font-weight: 700;
  line-height: 1.3;
}
div.aboutPage > div.advPart > p.content {
  font-size: 24px;
  padding-bottom: 32px;
}
div.aboutPage > div.advPart > img {
  padding-top: 96px;
}

div.aboutPage > div.offersPart {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 96px 72px;
  gap: 24px;
}
div.aboutPage > div.offersPart > p.title {
  font-size: 48px;
  font-weight: 700;
}
div.aboutPage > div.offersPart > p.content {
  font-size: 24px;
}
div.aboutPage > div.offersPart > p.content > a {
  text-decoration: underline;
}

@media (max-width: 1760px) {
  div.aboutPage > div.offersPart > p {
    font-size: 42px;
  }
}
@media (max-width: 1600px) {
  div.aboutPage > div.advPart > p.title {
    font-size: 60px;
  }
  div.aboutPage > div.offersPart > p {
    font-size: 38px;
  }
}