div.primaryTable {
  display: flex;
  flex-direction: column;
}
div.primaryTable > div.tableHeader {
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid black;
  padding-bottom: 12px;
}
div.primaryTable > div.tableHeader > span {
  text-align: start;
  padding-left: 8px;
}
div.primaryTable > div.tableBody {
  display: flex;
  flex-direction: column;
  height: 50vh;
  max-height: 480px;
  overflow-y: auto;
}
div.primaryTable > div.tableBody > div.tableRow {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--lightgray);
}
div.primaryTable > div.tableBody > div.tableRow > div.rowBody {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px 0;
}
div.primaryTable > div.tableBody > div.tableRow > div.rowBody > div {
  text-align: start;
  padding: 0px;
  padding-left: 8px;
}
div.primaryTable > div.tableBody > div.tableRow > div.rowBody > div > img {
  width: 32px;
  height: 32px;
  margin-right: 12px;
}
div.primaryTable > div.tableBody > div.tableRow > div.rowBody > div.actions > img {
  cursor: pointer;
}
div.primaryTable > div.tableBody > div.tableRow > div.additional {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
div.primaryTable > div.tableBody > div.tableRow > div.additional > div.button {
  margin: 0px;
  font-size: 20px;
  text-align: center;
  padding: 2px 14px;
  height: 40px;
}
div.primaryTable > div.tableBody > div.tableRow > div.additional > div.item {
  margin-right: 10px;
}
div.primaryTable > div.tableBody > div.tableRow > div.additional > div.item:first-child {
  width: 46%;
}
div.primaryTable > div.tableBody > div.tableRow > div.additional > div.item:nth-child(2) {
  width: 34%;
}
div.primaryTable > div.tableBody > div.tableRow > div.additional > div.item:last-child {
  width: 16%;
}

div.primary-dropdown > div.dropdown-header {
  font-size: 20px;
}